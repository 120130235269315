import { Component, Input } from '@angular/core';

import { AlertTypeList } from '@app/models';

export interface AlertMetadata {
  alerts: any;
  alertTypes: AlertTypeList;
  assetsTotal: number;
}

@Component({
  selector: 'app-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss']
})
export class AlertsListComponent {
  @Input() searchText: string;
  @Input() alertMetadata: AlertMetadata = null;
  @Input() isEditorViewEnabled: boolean = false;
}
