<div class="no-permission-page-container" >
  <div class="flex flex-row justify-start items-center u-margin__bottom--large">
    <a [href]="alertsLink" class="u-text--blue u-text--small">&#60; Back to Alerts</a>
  </div>

  <div class="flex flex-column justify-between items-center">
    <div class="icon"><mat-icon>not_interested</mat-icon></div>
    <h1 class="title">Not Authorized</h1>
    <div class="description">User must have company admin access to manage alerts.</div>
  </div>
</div>
