<div class="custom-schedule-dialog-wrapper">
  <div class="flex-row justify-between" mat-dialog-title>
    <div>Custom schedule</div>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="dialog-content-description">Alerts will only be created during the selected dates and times.</div>
    <form [formGroup]="scheduleForm">
      <ng-container *ngTemplateOutlet="dayTimeGroup; context: { $implicit: 'Activity starting', dayFormControl: scheduleForm.controls.startDay, timeFormControl: scheduleForm.controls.startTime }"></ng-container>
      <mat-divider></mat-divider>
      <ng-container *ngTemplateOutlet="dayTimeGroup; context: { $implicit: 'Activity ending', dayFormControl: scheduleForm.controls.endDay, timeFormControl: scheduleForm.controls.endTime }"></ng-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="flex flex-row" [ngClass]="isUpdateMode ? 'justify-between' : 'justify-end'">
    <div *ngIf="isUpdateMode">
      <button mat-button color="accent" [mat-dialog-close]="prepareData(true)">Delete</button>
    </div>
    <div>
      <button mat-stroked-button color="accent" class="cancel-button" mat-dialog-close>Cancel</button>
      <button mat-flat-button color="accent" class="save-button" [disabled]="isSaveButtonDisabled" [mat-dialog-close]="prepareData()">Save</button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template #dayTimeGroup let-title let-dayFormControl="dayFormControl" let-timeFormControl="timeFormControl">
  <div class="title">{{ title }}</div>
  <div class="flex flex-row justify-between">
    <ng-container *ngTemplateOutlet="selectField; context: { $implicit: dayFormControl, label: 'Day', options: dayOptions }"></ng-container>
    <ng-container *ngTemplateOutlet="selectField; context: { $implicit: timeFormControl, label: 'Time', options: timeOptions }"></ng-container>
  </div>
</ng-template>

<ng-template #selectField let-control let-label="label" let-options="options">
  <mat-form-field appearance="outline" [style.width.%]="47">
    <mat-label>{{ label }}</mat-label>
    <mat-select focused="false" [formControl]="control">
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
