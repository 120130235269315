import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { PermissionsGuard } from '@zonar-ui/auth';

import { AlertSetupPageComponent } from './pages/alert-setup/alert-setup.page.component';
import { AllAlertsPageComponent } from './pages/all-alerts/all-alerts.page.component';
import { AdminGuard } from './guards/admin.guard';
import { MyAlertsPageComponent } from './pages/my-alerts/my-alerts.page.component';
import { NoPermissionPageComponent } from './pages/no-permission/no-permission.page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AllAlertsPageComponent,
    canActivate: [AuthGuard, PermissionsGuard]
  },
  {
    path: 'add',
    pathMatch: 'full',
    component: AlertSetupPageComponent,
    canActivate: [AuthGuard, PermissionsGuard, AdminGuard]
  },
  {
    path: 'edit/:id',
    pathMatch: 'full',
    component: AlertSetupPageComponent,
    canActivate: [AuthGuard, PermissionsGuard, AdminGuard]
  },
  {
    path: 'myalerts',
    pathMatch: 'full',
    component: MyAlertsPageComponent,
    canActivate: [AuthGuard, PermissionsGuard],
  },
  {
    path: 'no-permission',
    pathMatch: 'full',
    component: NoPermissionPageComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
