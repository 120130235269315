<div ngClass.gt-sm="app-container-desktop" ngClass.lt-md="app-container-mobile" fxLayout="column" fxFill>
  <zui-sidenav
    [sidenavParams]="sidenavParams"
    [header]="sidenavHeader"
    (emitSelectedCompany)="switchToSelectedCompany($event)"
  >
    <div class="sidenav-container" fxFlex fxLayout="column">
      <zui-sidenav-header
        class="header-bar-sidenav"
        (menuToggled)="onSideNavMobileMenuButtonToggled($event)"
      ></zui-sidenav-header>
      <div class="content-container" fxFlex>
        <div class="page-container u-margin__top--xlarge">
          <router-outlet></router-outlet>
        </div>
      </div>
      <zui-footer-component *ngIf="device.mediaObserver.isActive('gt-sm')">
      </zui-footer-component>  
    </div>
  </zui-sidenav>
</div>
