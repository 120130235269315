<div class="notification-settings-container">
  <mat-expansion-panel [expanded]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title>Notification Settings</mat-panel-title>
      <mat-panel-description>Please select your preferred channel(s). At least one channel must be selected.</mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngIf="!isLoading; else loading">
      <form [formGroup]="notificationOptionsForm">
        <mat-slide-toggle class="email-notifications-toggle"
          [style.width.%]="100"
          formControlName="email">Receive email notifications</mat-slide-toggle>
        <mat-slide-toggle class="push-notifications-toggle"
          [style.width.%]="100"
          formControlName="fcm">Receive push notification</mat-slide-toggle>
      </form>
    </ng-container>
    <div *ngIf="!isGtcxMobile" class="flex flex-row justify-start additional-note">
      <div>Notification options can only be enabled from the mobile app</div>
      <mat-icon (click)="openGetMobileAppDialog()">info_outline</mat-icon>
    </div>
  </mat-expansion-panel>
</div>

<ng-template #loading>
  <div class="loading-container">
    <ngx-skeleton-loader
      count="2"
      [theme]="{
        height: '24px',
        'border-radius': '3px',
        'background-color': '#E3E3E3'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>
