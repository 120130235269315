import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Asset, PaginatedResponse } from '@app/models';
import { ENV } from '@environments/environment.provider';
import { Observable, throwError, ReplaySubject, forkJoin, of, combineLatest } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminService } from '@app/services/admin.service';
import { ECompanyLoginMode, PermissionsService } from '@zonar-ui/auth';
import { IUserGroupPolicy } from '@zonar-ui/auth/lib/models/user-group-policy.model';
import { CompanyService } from './company-store.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class AssetApiHttpService {
  ASSETS_API_URL = this.env.coreEntityApiBase.url + '/assets';
  USERS_API_URL = this.env.coreEntityApiBase.url + '/users';
  USERPROFILES_API_URL = this.env.coreEntityApiBase.url + '/userprofiles';
  APP_ID = this.env.auth.applicationId;
  ACTIVITY_FEED_APP_ID = this.env.activityFeedBase.applicationId;

  private allAssets$: ReplaySubject<Asset[]> = new ReplaySubject<Asset[]>(1);

  constructor(
    @Inject(ENV) private env: any,
    private _httpService: HttpClient,
    private _adminService: AdminService,
    private _permissionsService: PermissionsService,
    private _companyService: CompanyService,
    private _utilitiesService: UtilitiesService
  ) {}

  getAssetsFromCache(): Observable<Asset[]> {
    return this.allAssets$.asObservable();
  }

  setAssetsToCache(assets: Asset[]): void {
    this.allAssets$.next(assets);
  }

  getAssets(companyId: string): Observable<Asset[]> {
    return this._permissionsService.getIsPermissionsLoaded().pipe(
      filter((isLoaded) => isLoaded), // Ensure permissions are loaded before continuing
      switchMap(() =>
        this._utilitiesService.getCompanyLoginMode().pipe(
          switchMap((loginMode) => {
            if (loginMode === ECompanyLoginMode.GROUP_POLICY) {
              return this._getAssetsForGroupPolicyContext();
            } else {
              return this._getAssetsForUserProfileContext(companyId);
            }
          })
        )
      )
    );
  }

  private getAllAssetsForCompanyOrDivision(companyId: string, divisionId?: string): Observable<Asset[]> {
    const perPage = 1000;
    let assetUrl = `${this.ASSETS_API_URL}?companyId=${companyId}&status=ACTIVE&per_page=${perPage}&page=1`;

    // If divisionId is provided, include it in the query parameters
    if (divisionId) {
      assetUrl += `&divisionId=${divisionId}`;
    }

    console.time(`getAllAssets - ${divisionId ? 'Division' : 'Company'} - ${divisionId || companyId}`);

    return this._httpService.head(assetUrl, { observe: 'response' }).pipe(
      switchMap((response) => {
        const totalCount = Number(response.headers.get('x-total-count')); // Total assets
        const totalPages = Math.ceil(totalCount / perPage); // Calculate total pages

        if (totalCount === 0) {
          console.log(`No assets found for ${divisionId ? 'division' : 'company'} ${divisionId || companyId}`);
          return of([]); // Return empty array when no assets found
        }

        console.log(
          `Total Assets for ${divisionId ? 'division' : 'company'} ${divisionId || companyId}: ${totalCount}, Total Pages: ${totalPages}, `
        );

        // Create an array of observables for each page
        const pageRequests: Observable<PaginatedResponse<Asset>>[] = [];
        for (let page = 1; page <= totalPages; page++) {
          const paginatedUrl = `${this.ASSETS_API_URL}?companyId=${companyId}&status=ACTIVE`;
          if (divisionId) {
            pageRequests.push(this._utilitiesService.getPaginatedData<Asset>(`${paginatedUrl}&divisionId=${divisionId}`, page, perPage));
          } else {
            pageRequests.push(this._utilitiesService.getPaginatedData<Asset>(paginatedUrl, page, perPage));
          }
        }

        return forkJoin(pageRequests);
      }),
      map((results: PaginatedResponse<Asset>[]) => {
        console.timeEnd(`getAllAssets - ${divisionId ? 'Division' : 'Company'} - ${divisionId || companyId}`);
        return results.reduce((acc, result) => acc.concat(result.items), [] as Asset[]);
      }),
      catchError((error) => {
        console.timeEnd(`getAllAssets - ${divisionId ? 'Division' : 'Company'} - ${divisionId || companyId}`);
        console.error('Error fetching all assets:', error);
        return throwError(() => new Error(`Error fetching all assets for ${divisionId ? 'division' : 'company'}`));
      })
    );
  }

  /**
   * Filter user group policies by Company ID
   */
  private _getUserGroupPoliciesFilteredByCompanyId(): Observable<Array<IUserGroupPolicy>> {
    return this._permissionsService.getUserGroupPolicies().pipe(
      filter((x) => !!x),
      switchMap((userPolicies) => {
        return this._companyService.currentCompany$.pipe(
          map((company) => {
            return userPolicies.filter((policy) => policy.policy.companyId === company.value);
          })
        );
      })
    );
  }

  private _getAssetsForUserProfileContext(companyId): Observable<any> {
    // Check if the user is a company admin
    return this._adminService.isCompanyAdmin$.pipe(
      switchMap((isCompanyAdmin: boolean) => {
        if (isCompanyAdmin) {
          // Company admin: get all assets for the company
          return this.getAllAssetsForCompanyOrDivision(companyId).pipe(
            tap((assets: Asset[]) => {
              console.log(`Total assets for company admin: ${assets.length}`);
            })
          );
        } else {
          // Not a company admin, check if partial admin
          return this._adminService.isPartialAdmin$.pipe(
            switchMap((isPartialAdmin: boolean) => {
              if (isPartialAdmin) {
                // Partial admin: fetch user divisions
                return this.getUserDivisions(companyId).pipe(
                  switchMap((divisions: string[]) => {
                    if (divisions.length > 0) {
                      // Fetch assets for each division
                      return this.getAllAssetsForDivisions(companyId, divisions).pipe(
                        tap((assets: Asset[]) => {
                          console.log(`Total assets for partial admin: ${assets.length}`);
                        })
                      );
                    } else {
                      console.log('No divisions found for partial admin');
                      return of([]); // Return empty array when no divisions are found
                    }
                  })
                );
              } else {
                console.log('Neither company admin nor partial admin - throwing error');
                return throwError(new Error('User is not authorized to view assets.'));
              }
            })
          );
        }
      }),
      // Sort assets by name and filter out assets with blank names
      map((assets) => assets.filter((asset) => asset.name.trim() !== '')),
      map((assets) => assets.sort((a, b) => a.name.localeCompare(b.name))),
      catchError((error) => {
        console.error('Failed to load assets', error);
        this.allAssets$.error('Failed to load assets');
        return throwError(() => new Error('Failed to load assets'));
      })
    );
  }

  private _getAssetsForGroupPolicyContext(): Observable<any> {
    return this._getUserGroupPoliciesFilteredByCompanyId().pipe(
      switchMap((response) => {
        const uniqueTenantIdsSet = new Set();
        response.forEach((policy) => {
          uniqueTenantIdsSet.add(policy.tenant.id);
        });

        const uniqueTenantIdsArray = Array.from(uniqueTenantIdsSet);
        let uniqueTenantIdsObservables = [];
        uniqueTenantIdsArray.forEach((id) => {
          uniqueTenantIdsObservables.push(this._getAssetsByTenant(id));
        });
        return combineLatest(...uniqueTenantIdsObservables).pipe(
          map((val) => {
            const output = this._utilitiesService.deduplicateArrayByValue(val.flat(), 'id');
            return output;
          })
        );
      })
    );
  }

  /**
   * Gets all assets from all divisions that are provided in each tenant of the user policies call
   * @param tenantId the tenant id from the user policy
   * @returns all assets for the divisions of the relevant tenant
   */
  private _getAssetsByTenant(tenantId): Observable<Asset[]> {
    const groupsApiUrl = `${this.env.coreEntityApiBase.url}/groups?tenantId=${tenantId}`;
    return this._companyService.currentCompany$.pipe(
      switchMap((company) => {
        return this._utilitiesService.getAllPages(groupsApiUrl).pipe(
          map((groups: Array<any>) => {
            const divisionsArrays = [];
            groups.forEach((group) => {
              divisionsArrays.push(group.tenant.scope.divisions);
            });
            const flattenedDivisions = divisionsArrays.flat();
            const uniqueDivisionIds = new Set<string>();
            flattenedDivisions.map((division) => {
              uniqueDivisionIds.add(division.id);
            });
            return Array.from(uniqueDivisionIds);
          }),
          switchMap((uniqueDivisionIds) => {
            return uniqueDivisionIds.length
              ? this.getAllAssetsForDivisions(company.value, uniqueDivisionIds)
              : this.getAllAssetsForCompanyOrDivision(company.value);
          })
        );
      })
    );
  }

  private getAllAssetsForDivisions(companyId: string, divisions: string[]): Observable<Asset[]> {
    console.time('getAllAssetsForDivisions'); // Start timer

    // Fetch assets for each division separately
    const divisionRequests = divisions.map((divisionId) => {
      return this.getAllAssetsForCompanyOrDivision(companyId, divisionId);
    });

    // Combine the results from all division requests
    return forkJoin(divisionRequests).pipe(
      map((results: Asset[][]) => {
        console.timeEnd('getAllAssetsForDivisions'); // End timer
        return results.reduce((acc, assets) => acc.concat(assets), [] as Asset[]); // Flatten the array of arrays
      }),
      catchError((error) => {
        console.timeEnd('getAllAssetsForDivisions'); // End timer on error
        console.error('Error fetching assets for divisions:', error);
        return throwError(() => new Error('Error fetching assets for divisions'));
      })
    );
  }

  private getUserDivisions(companyId: string): Observable<string[]> {
    return this._permissionsService.getUser().pipe(
      switchMap((user) => {
        const userId = user.id;
        const url = `${this.USERS_API_URL}/${userId}/companies/${companyId}/divisions?applicationId=${this.ACTIVITY_FEED_APP_ID}&allChildren=true`;
        console.log('Fetching user divisions:', url);
        return this._httpService.get<{ divisions: string[] }>(url).pipe(
          map((response) => response.divisions || []),
          tap((divisions) => console.log('getUserDivisions in Assets: divisions:', divisions)),
          catchError((error) => {
            console.error('Error fetching user divisions', error);
            return of([]); // Return empty array on error
          })
        );
      })
    );
  }
}
