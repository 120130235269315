import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UserPreference } from '@app/models';
import { environment } from '@environments/environment';
import { httpRetryCount } from '@environments/shared';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  USER_PREFERENCE_API_URL = `${environment.userPreferenceBase}/preferences`;

  constructor(private _httpClient: HttpClient) { }

  public get(companyId: string, userInfo: Record<string, string>): Observable<UserPreference> {
    return this._httpClient.get<UserPreference[]>(`${this.USER_PREFERENCE_API_URL}?customer_user_id=${userInfo.id}&company_id=${companyId}&status=ACTIVE`).pipe(
      retry(httpRetryCount),
      map(([existingUserPreference]) => existingUserPreference),
      catchError(() => of(null)),
      switchMap(existingUserPreference => {
        if (existingUserPreference === null) {
          return of();
        }

        if (existingUserPreference) {
          return of(existingUserPreference);
        }

        return this.post(companyId, userInfo);
      }),
    );
  }

  public patch<T>(id: string, data: Record<string, number>, modified: string): Observable<T> {
    const headers = new HttpHeaders({
      'if-match': modified,
      'Content-Type': 'application/json-patch+json',
    });
    const body = Object.keys(data).map(key => ({ op: 'replace', path: `/contact_priority/${key}`, value: data[key] }));
    return this._httpClient.patch<T>(`${this.USER_PREFERENCE_API_URL}/${id}`, body, { headers } );
  }

  public post(companyId: string, userInfo: Record<string, string>): Observable<UserPreference> {
    const body = this._buildUserPreferenceBody(companyId, userInfo);
    return this._httpClient.post<UserPreference>(`${this.USER_PREFERENCE_API_URL}`, body);
  }

  private _buildUserPreferenceBody(companyId: string, userInfo: Record<string, string>) {
    return {
      name: `${userInfo.id}${companyId} User Preference`,
      description: 'User Preference',
      status: 'ACTIVE',
      customerUserId: userInfo.id,
      companyId,
      contactDetails: {
        phoneNumber: [],
        emailAddress: [userInfo.email],
        whatsapp: [],
        slack: [],
        userIds: [userInfo.id],
        fcmDeviceToken: [],
      },
      contactPriority: {
        sms: 0,
        email: 1,
        slack: 0,
        whatsapp: 0,
        fcm: 0,
      },
      activeDuration: {
        startDatetime: '2024-05-21T09:52:49.922000+00:00',
        endDatetime: null,
      },
      emailTemplateId: null,
      smsTemplateId: null,
      whatsappTemplateId: null,
      slackTemplateId: null,
      fcmTemplateId: null,
    };
  }
}
