import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AdminService } from '@app/services/admin.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private _adminService: AdminService, private _router: Router) {}
  canActivate(): Observable<boolean> {
    return this._adminService.isAnyAdmin$.pipe(
      map((isAdmin) => {
        if (!isAdmin) {
          this._router.navigateByUrl('/');
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
