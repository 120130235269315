<div class="dialog-content">
  <div class="dialog-title" mat-dialog-title>
    Delete alert
  </div>
  <div class="dialog-body u-margin__bottom--normal" mat-dialog-content>
    All current recipients will permanently stop receiving 
    this alert. If you'd like to temporarily stop this alert, 
    tap Cancel and Inactivate the alert instead.
  </div>
</div>
<div class="dialog-button-container">
  <button type="button" mat-stroked-button color="accent" (click)="onCancel()">Cancel</button>
  <button type="button" mat-flat-button color="accent" (click)="onConfirm()">Delete</button>
</div>
