<div class="my-alerts-container">
  <div class="flex flex-row justify-start items-center">
    <a class="u-text--blue u-text--small" [href]="alertsLink">&#60; Back to Alerts</a>
  </div>
  <div class="flex flex-row justify-between items-center u-margin__bottom--large">
    <h1>My alerts</h1>
    <button *ngIf="isAdmin$ | async"
      class="manage-alerts-button"
      mat-stroked-button
      color="accent"
      type="button"
      [routerLink]="alertsManagementLink">
      <mat-icon class="btn-icon">settings</mat-icon>
      <span class="btn-title">Manage company alerts</span>
    </button>
  </div>
  <app-notification-settings *ngIf="(isPushNotificationsFeatureDisabled$ | async) === false"></app-notification-settings>
  <div class="alert-list-content">
    <div class="u-text--large alert-list-title">My Alert Subscriptions</div>
    <app-alerts-list [alertMetadata]="alertMetadata"></app-alerts-list>
  </div>
</div>
