import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

type CallBackFunction = () => void

interface DialogData {
  title: string;
  message: string;
  imageUrl?: string;
  cancelText?: string;
  acceptText: string;
  onCancel?: CallBackFunction;
  onAccept?: CallBackFunction;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  title: string;
  message: string;
  imageUrl: string;
  cancelText: string;
  acceptText: string;
  onCancel: CallBackFunction;
  onAccept: CallBackFunction;

  constructor(@Inject(MAT_DIALOG_DATA) data: DialogData) {
    if (data) {
      const voidFunction = () => { };
      this.title = data.title;
      this.message = data.message;
      this.imageUrl = data.imageUrl;
      this.cancelText = data.cancelText;
      this.acceptText = data.acceptText;
      this.onCancel = data.onCancel || voidFunction;
      this.onAccept = data.onAccept || voidFunction;
    }
  }

  handleCancel() {
    this.onCancel();
  }

  handleAccept() {
    this.onAccept();
  }
}
