import { appName, appVersion } from '../../environments/shared';

import { Injectable } from '@angular/core';
import { PermissionsService } from '@zonar-ui/auth';
import { User } from '@auth0/auth0-spa-js';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  private user: User;

  constructor(private permissions: PermissionsService) {
    if (this.isRumEnabled()) {
      const { applicationId, clientToken, siteUrl, env } = environment.datadog;
      // enable real user monitoring for all non-local (deployed) environment(s)
      datadogRum.init({
        applicationId,
        clientToken,
        env,
        service: appName,
        version: appVersion,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        replaySampleRate: 100,
        silentMultipleInit: true,
        trackUserInteractions: true,
        allowedTracingOrigins: [/https:\/\/.*\.zonarsystems\.net/],
        site: siteUrl,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogLogs.init({
        clientToken,
        env,
        service: appName,
        silentMultipleInit: true,
        site: siteUrl,
        forwardErrorsToLogs: false,
        sampleRate: 100
      });

      this.permissions
        .getUsers()
        .pipe(first((users) => Boolean(users?.length)))
        .subscribe((user) => {
          this.user = user[0];
          datadogRum.setUser({
            id: this.user.id,
            email: this.user.email,
            sub: this.user.sub
          });
        });
    }
  }

  isLocalDevEnv() {
    return globalThis?.location?.origin?.includes('localhost');
  }

  isRumEnabled() {
    return environment.datadog.enabled && !this.isLocalDevEnv();
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (this.isRumEnabled()) {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (this.isRumEnabled()) {
      datadogRum.addError(error, context);
    }
  }

  addRumTiming(name: string, time?: number | undefined): void {
    if (this.isRumEnabled()) {
      datadogRum.addTiming(name, time || Date.now());
    }
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    if (this.isRumEnabled()) {
      datadogRum.startSessionReplayRecording();
    }
  }

  stopSessionReplayRecording(): void {
    if (this.isRumEnabled()) {
      datadogRum.stopSessionReplayRecording();
    }
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    if (this.isRumEnabled()) {
      datadogLogs.logger.log(message, attributes, status);
    }
  }
}
