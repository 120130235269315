import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DAY_OPTIONS, TIME_OPTIONS } from '@app/constants';

function customScheduleValueValidator(): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const { startDay, startTime, endDay, endTime } = formGroup.value;
    return startDay && startTime && endDay && endTime && startDay === endDay && startTime === endTime ? { duplicateStartAndEndTimes: true } : null;
  };
}

interface CustomScheduleDialogData {
  startTime: string;
  endTime: string;
  isUpdateMode: boolean;
}

@Component({
  selector: 'app-custom-schedule-dialog',
  templateUrl: './custom-schedule-dialog.component.html',
  styleUrls: ['./custom-schedule-dialog.component.scss']
})
export class CustomScheduleDialogComponent {
  scheduleForm: FormGroup;
  isUpdateMode: boolean;
  dayOptions: string[] = DAY_OPTIONS;
  timeOptions: string[] = TIME_OPTIONS;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CustomScheduleDialogData,
    private formBuilder: FormBuilder,
  ) {
    const [startDay, startTime] = data.startTime.split(/ (.*)/);
    const [endDay, endTime] = data.endTime.split(/ (.*)/);
    this.scheduleForm = this.formBuilder.group({
      startDay: [startDay || '', Validators.required],
      startTime: [startTime || '', Validators.required],
      endDay: [endDay || '', Validators.required],
      endTime: [endTime || '', Validators.required],
    }, { validators: [customScheduleValueValidator()] });
    this.isUpdateMode = data.isUpdateMode;
  }

  get isSaveButtonDisabled(): boolean {
    return this.scheduleForm?.pristine || this.scheduleForm?.invalid;
  }

  prepareData(deleteCustomSchedule: boolean = false) {
    return { ...this.scheduleForm.value, isCustomScheduleDeleted: deleteCustomSchedule };
  }
}
