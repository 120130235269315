import { Injectable } from '@angular/core';
import { initializeDevCycle, DVCVariableSet } from '@devcycle/js-client-sdk';
import { of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private devCycleClient;
  private variables: DVCVariableSet;
  private variablesInitialized = false;
  public variableSubject: ReplaySubject<DVCVariableSet> = new ReplaySubject();

  constructor() { }

  initializeDevCycle(userId: string, companyId: string, appVersion: string) {
    const dvcUser = {
      user_id: userId,
      appVersion: appVersion || 'null',
      customData: { company_id: companyId }
    };
    this.devCycleClient = initializeDevCycle(environment.devCycle.clientId, dvcUser);
    this.devCycleClient.onClientInitialized().then(client => {
      this.variables = client.allVariables();
      this.variablesInitialized = true;
      this.variableSubject.next(client.allVariables());
    });

    // https://docs.devcycle.com/docs/sdk/client-side-sdks/javascript#subscribing-to-sdk-events
    this.devCycleClient.subscribe('variableUpdated:*', (key, val) => {
      if (this.variablesInitialized) {
        this.variables[key] = val;
        this.variableSubject.next(this.variables);
      }
    });
  }

  // this returns the value of the DevCycle variable
  // which could be type: Boolean, String, Number, JSON
  // see https://docs.devcycle.com/docs/home/feature-management/features-and-variables/creating-variables-and-variations
  valueForKey(key: string) {
    return this.variableSubject.pipe(map(variables => variables[key]?.value));
  }

  isBoolean(val) {
    return typeof val === 'boolean';
  }

  // this returns a boolean Observable & should be used in templates to determine if the feature is enabled
  isFeatureEnabled(key: string) {
    return this.variableSubject.pipe(
      switchMap(variables => {
        const keyVal = variables[key]?.value;
        if (this.isBoolean(keyVal)) {
          return of(keyVal);
          // later on we could add other logic here, if value was a number or string or some object
          // but for now we only have booleans, so the no-op is to return false
        } else {
          return of(false);
        }
      })
    );
  }
}
