<mat-card class="u-margin__bottom--normal">
  <div class="title">{{ alertData.eventType.name }}</div>
  <div class="description">
    <span class="u-text--semibold">{{ typeOptionsMap[alertData.eventType.id] }}</span> for
    <span class="u-text--semibold">{{ alertData.allAssets ? assetsTotal : alertData.entities.length }} assets</span> to
    <span class="u-text--semibold">{{ alertData.recipients.length }} recipients</span> <span *ngIf="isEditorViewEnabled"> via <mat-icon>mail_outline</mat-icon></span>
  </div>
  <div *ngIf="isEditorViewEnabled" class="status">Status: {{ alertData.status }}</div>
  <div class="flex flex-row items-end content-bottom" [ngClass]="alertData.lastModifiedUserName ? 'justify-between' : 'justify-end'">
    <div *ngIf="alertData.lastModifiedUserName" class="modified-info">Changes made by {{ alertData.lastModifiedUserName }} on {{ lastModifiedDate }}</div>
    <ng-container *ngIf="isEditorViewEnabled && (isAdmin$ | async)">
      <button mat-stroked-button
        color="accent"
        data-e2e="edit-button"
        [routerLink]="['edit', alertData.subscription_id]">Edit</button>
    </ng-container>
  </div>
</mat-card>
