import { Injectable } from '@angular/core';
import { PermissionsService } from '@zonar-ui/auth';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertsPermissionService extends PermissionsService {
  _isZonarUserProfile(profile): boolean {
    return (
      profile.roles.some((role) => 
        (role.id === environment.companyAdminRole) || (role.id === environment.partialAdminRole) )  &&
        !profile.companyId &&
        profile.applicationId === environment.auth.applicationId
    );
  }
}
