<span class="container" *ngIf="alertMetadata !== null; else loading">
  <span *ngIf="alertMetadata && alertMetadata.alerts.length > 0; else emptyMessage">
    <app-alert-info-row
      *ngFor="let alertData of alertMetadata.alerts | searchFilter : searchText"
      [alertData]="alertData"
      [alertTypes]="alertMetadata.alertTypes"
      [assetsTotal]="alertMetadata.assetsTotal"
      [isEditorViewEnabled]="isEditorViewEnabled"
    ></app-alert-info-row>
  </span>
</span>
<ng-template #loading
  ><div class="loading-container"><mat-spinner></mat-spinner></div
></ng-template>
<ng-template #emptyMessage>
  <div class="flex flex-column items-center u-text--gray empty-message">
    <div class="header u-margin__bottom--small">No Current Alerts</div>
    <div class="description">Create alerts to help keep your users up to date with the latest fleet changes</div>
  </div>
</ng-template>
