import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedResponse } from '@app/models';
import { httpRetryCount } from '@environments/shared';
import { ECompanyLoginMode, PermissionsService } from '@zonar-ui/auth';
import { combineLatest, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, expand, filter, map, reduce, retry, switchMap } from 'rxjs/operators';
import { CompanyService } from './company-store.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(private _permissionsService: PermissionsService, private _companyService: CompanyService, private _httpService: HttpClient) {}

  /**
   * Returns the company login mode for the currently selected company
   * @returns Observable of login mode for the currently selected company
   */
  getCompanyLoginMode(): Observable<ECompanyLoginMode> {
    return this._permissionsService.getIsPermissionsLoaded().pipe(
      filter((isLoaded) => isLoaded),
      switchMap(() => combineLatest([this._companyService.currentCompany$, this._permissionsService.getCompanyLoginMode()])),
      filter(([selectedCompany, companies]) => !!selectedCompany && !!companies),
      map(([selectedCompany, companies]) => {
        const company = companies.find((company) => selectedCompany.value === company.id);
        return company ? company.loginMode : ECompanyLoginMode.USER_PROFILE;
      })
    );
  }

  /**
   * Gets results from Core Entity API for a specific page
   * @param url URL to call for pagination
   * @param page Which page to retrieve
   * @param perPage How many results there are per page
   * @returns An Observable of the paginated response
   */
  getPaginatedData<T>(url: string, page: number, perPage: number = 1000): Observable<PaginatedResponse<T>> {
    const paginatedUrl = `${url}&per_page=${perPage}&page=${page}`;
    return this._httpService.get<T[]>(paginatedUrl).pipe(
      retry(httpRetryCount),
      map((items) => {
        const hasNextPage = items.length === perPage;
        return {
          items,
          nextPage: hasNextPage ? page + 1 : undefined
        };
      })
    );
  }

  /**
   * Merges results of all paginated calls
   * @param url URL to call for pagination
   */
  getAllPages<T>(url: string): Observable<T[]> {
    return this.getPaginatedData<T>(url, 1).pipe(
      expand((data) => {
        return data.nextPage
          ? this.getPaginatedData<T>(url, data.nextPage).pipe(
              catchError((error) => {
                console.error('Error fetching page: ', data.nextPage, error);
                return throwError(() => new Error('Error fetching page'));
              })
            )
          : EMPTY;
      }),
      reduce((acc, data) => acc.concat(data.items), [] as T[])
    );
  }

  /**
   * Deduplicates objects in array of objects by the key "value"
   * @param arr Array to deduplicate
   * @returns Array without the duplicate objects
   */
  deduplicateArrayByValue(arr, dedupeKey) {
    const seen = new Set();
    const deduplicatedArray = [];
    arr.forEach((item) => {
      const value = item[dedupeKey];
      if (!seen.has(value)) {
        seen.add(value);
        deduplicatedArray.push(item);
      }
    });
    return deduplicatedArray;
  }
}
