export const SidenavHeaderConfig = {};

export const SidenavMenuConfig = [];

export const SidenavFooterConfig = {
  items: []
};

export const SidenavParamsConfig = {
  expanded: true,
  mobileOpened: true,
  footerOffset: false,
  disableBackdropClose: false
};
