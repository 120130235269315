export const environment = {
  name: 'production-na',
  region: 'NA',
  production: true,
  partialAdminRole: 'a7c099f3-83fa-405f-abd5-2d89e0526d04',
  companyAdminRole: 'bb6df02f-a35b-4419-81eb-00906d7da84f',
  appUrl: 'https://alertmanagement.production.zonarsystems.net',
  apiBase: {
    url: 'https://event-subscription-api.production.zonarsystems.net/v1'
  },
  gtcxMapsBase: 'https://maps.zonarsystems.net',
  activityFeedBase: {
    link: 'https://alerts.zonarsystems.net/',
    applicationId: '239154b3-08e1-4e1e-8d2a-80553e4c9707',
  },
  thresholdSettingsBase: {
    link: 'https://settings.zonarsystems.net/',
    applicationId: 'c607bca3-5909-4b0a-8044-456ed8f63ed9',
    adminRoleId: 'c6be2c4f-ac90-4a3b-bcfa-47d125e1effb'
  },
  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  authEnv: 'production',
  auth: {
    clientID: 'RESqGm4uTpFqAspMKsWDuB83AI42LndP',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    applicationId: '2c51e6ee-5b9a-48c8-b45e-da68991a4c27',
    defaultZonarRole: 'bb6df02f-a35b-4419-81eb-00906d7da84f',
    useRefreshTokens: true
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },
  datadog: {
    enabled: true,
    applicationId: 'c6726179-145e-4a20-9043-f23f3c26a956',
    clientToken: 'pub7d9e4520371e38855c316f364e250dd2',
    siteUrl: 'us5.datadoghq.com',
    env: 'production'
  },
  i18n: {
    supportedLanguages: ['en', 'en-US', 'de-DE', 'en-GB', 'it', 'fr-FR', 'de', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  devCycle: {
    clientId: 'dvc_client_99c9590c_3f43_4b61_a26b_6898f682f0d7_37976ef'
  },
  userPreferenceBase: 'https://api.zonarsystems.net/alert/v1',
};
