<div class="dialog-wrapper">
  <div mat-dialog-title>{{ title }}</div>
  <mat-dialog-content class="flex-column justify-start items-center">
    <div>{{ message }}</div>
    <img *ngIf="imageUrl" [src]="imageUrl">
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="cancelText"
      mat-stroked-button
      color="accent"
      mat-dialog-close
      (click)="handleCancel()">{{ cancelText }}</button>
    <button mat-flat-button
      color="accent"
      mat-dialog-close
      (click)="handleAccept()">{{ acceptText }}</button>
  </mat-dialog-actions>
</div>
